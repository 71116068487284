.container {
    height: 100vh;
    display: flex;
    margin: auto;
    color: #59e1ac;
    font-size: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

@media screen and (max-width:545px) {
    .container {
        font-size: 30px;
    }
}