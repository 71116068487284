@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.container {
    display: flex;
    width: 90%;
    flex-direction: column;
    position: relative;
    z-index: 0;
    background-color: black;
    margin: auto;
    margin-top: 50px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    color: white;
    margin-bottom: 100px;
    padding-bottom: 100px;
}

.first {
    display: flex;
    flex-direction: row;
    gap: 0;
    padding: 30px;
    padding-bottom: 0;
}

.firstheading {
    color: #aaaaaa;
    font-size: 20px;
}

.firstline {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #62cf78;
    line-height: 0.1em;
    margin: 10px 0 20px;
    margin-bottom: 31px;
    width: 130px;
    margin-left: 20px;

}

.second {
    padding: 30px;
    padding-top: 0;
    margin-top: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 0;
}

.third {
    padding: 30px;
    display: flex;
    font-family: 'Nunito', sans-serif;
    margin-top: 0;
    padding-top: 0;
    gap: 15%;
}

.thirdleft {
    width: 30%;

}

.aboutpic {
    width: 320px;
    height: 320px;
    border-radius: 100%;
    object-fit: cover;
    border-left: 3px solid #62cf78;
}

.thirdright {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.thirdright1 {
    font-size: 25px;
    color: #62cf78;
    font-weight: 700;

}

.thirdright3 {
    display: flex;

}

.thirdright3a {
    width: 50%;
}

.arrow {
    font-size: 20px;
    color: #62cf78;
}

.thirdheading {
    font-size: 20px;
    font-weight: 600;
}

.forth {
    padding: 30px;
    display: flex;
}

.forthleft {
    width: 50%;
}

.forthright {
    width: 50%;
}

.forthleft1 {
    font-size: 35px;
    font-weight: 600;

}

.timeline {
    width: 100%;
    border-left: 2px solid #262626;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.leftitem {

    width: 100%;



}

.leftitem::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #62cf78;
    position: relative;
    left: -10px;
    top: 20px;

}

.leftiteminner {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 0;
}


.leftitemheading {
    color: #62cf78;
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
}

.leftiteminnercontent1 {
    font-size: 16px;
    background-color: #272727;
    width: 25%;
    display: flex;
    margin: 10px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.leftiteminnercontent2 {
    font-size: 16px;
    margin: 10px;
    padding: 0px;


}

.rightiteminner {
    display: grid;
    color: white;
    grid-template-columns: repeat(auto-fill, minmax(140px, 4fr));
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.rightiteminnercontent1 {
    font-size: 16px;
    background-color: #272727;
    display: flex;
    margin: 10px;
    padding: 8px 12px;

    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
}

.arrowE {
    font-size: 20px;
    color: #62cf78;
    position: absolute;
    left: 30px;

}

.extraitem {
    padding-left: 60px;
    display: flex;
    align-items: center;
    height: 30px;
}

.fifthleft1 {
    font-size: 35px;
    font-weight: 600;
    padding-left: 30px;
}

@media screen and (max-width:985px) {
    .bottomdiv {
        display: flex;
        flex-direction: column;
    }

    .third {
        flex-direction: column;
        gap: 50px;
    }

    .thirdleft {
        width: 90%;
    }

    .thirdright {
        width: 90%;
    }

    .forth {
        flex-direction: column;
        gap: 50px;
    }

    .forthleft {
        width: 90%;
    }

    .forthright {
        width: 90%;
    }
}

@media screen and (max-width: 910px) {
    .thirdright3 {
        flex-direction: column;
        gap: 0;
    }

    .thirdright3a {
        width: 100%;


    }

    .thirdright3b {
        width: 100%;
        margin-top: -20px;
    }
}

@media screen and (max-width: 561px) {
    .second {
        font-size: 30px;
    }

    .aboutpic {
        height: 270px;
        width: 270px;
    }

    .thirdright1 {
        font-size: 20px;
    }

    .thirdright2 {
        font-size: 14px;
    }

    .thirdheading {
        font-size: 15px;
    }

    .arrow {
        font-size: 14px;
    }

    .thirdright4 {
        font-size: 14px;
    }

    .thirdcontent {
        font-size: 12px;
    }

    .fifthleft1 {
        font-size: 25px;
    }

    .leftiteminnercontent1 {
        width: 35%;
    }
}

@media screen and (max-width: 480px) {
    .second {
        font-size: 25px;
    }

    .aboutpic {
        height: 200px;
        width: 200px;
    }

    .thirdright4 {
        font-size: 12px;
    }

    .leftiteminnercontent1 {
        width: 50%;
    }

    .fifthleft1 {
        font-size: 20px;
    }

    .extraitem {
        font-size: 14px;
    }
}

.bottomdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.leetcodelogo {
    height: 17px;
    width: 17px;
    border-radius: 5px;
    margin-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
}