@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.container {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    color: white;
    position: relative;
    margin-bottom: 80px;
    padding-bottom: 40px;
}

.first {
    display: flex;
    flex-direction: row;
    gap: 0;
    padding: 30px;
    padding-bottom: 0;
}

.firstheading {
    color: #aaaaaa;
    font-size: 20px;
}

.firstline {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #62cf78;
    line-height: 0.1em;
    margin: 10px 0 20px;
    margin-bottom: 31px;
    width: 130px;
    margin-left: 20px;

}

.second {
    padding: 30px;
    padding-top: 0;
    margin-top: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 0;
}

.maincard {
    color: #cccccc;

    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: rgb(54, 54, 54);
    font-family: 'Nunito', sans-serif;
    transition: all 400ms ease;

}

.maincard1 {
    color: #cccccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;
    background-color: rgb(54, 54, 54);
    font-family: 'Nunito', sans-serif;
    transition: all 400ms ease;
    border-left: #62cf78 2px solid;
    min-height: 220px;

}

.expheight {
    max-height: 0px;
}

.exp1 {
    color: white;
    font-size: 20px;
}

.maincard:hover {
    animation: shake 0.5s;
}


@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.card1 {
    height: 250px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    filter: blur(3px);
    transition: all 400ms ease;
}

.card1:hover {
    filter: blur(0);

}


.card2 {
    padding: 10px;
    font-size: 25px;
    font-weight: 500;

}

.card3 {
    padding: 10px;
    font-size: 16px;
    font-weight: 400;

}

.card4 {
    padding: 10px;
    font-size: 14px;
}

.card5 {
    padding: 10px;
    display: flex;
    flex-direction: row;

    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 70px;
    margin-bottom: 20px;
}

.cardbutton {
    padding: 8px 15px;
    font-size: 18px;
    color: white;
    background-color: #555555;
    border-radius: 10px;
    border: none;
}

.cardbutton:hover {}

.cardbuttonno {
    padding: 8px 15px;
    font-size: 18px;
    color: white;
    background-color: #555555;
    border-radius: 10px;
    border: none;
    opacity: 0.5;
}

.cardbuttonno:hover {
    cursor: not-allowed;
}

.third {
    padding: 30px;


}

.griddiv {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 4fr));
    row-gap: 60px;
    grid-column-gap: 60px;
    align-items: center;
}

.third1 {
    padding: 30px;


}

.griddiv1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 2fr));
    row-gap: 60px;
    grid-column-gap: 60px;
    align-items: center;
}

@media screen and (max-width:920px) {
    .card1 {
        object-fit: cover;
    }
}

@media screen and (max-width:648px) {
    .griddiv1 {
        grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));

    }
}

@media screen and (max-width:523px) {
    .cardbutton {
        font-size: 15px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .cardbuttonno {
        font-size: 15px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width:465px) {
    .container {
        width: 100%;
    }

    .griddiv {

        grid-template-columns: repeat(auto-fill, minmax(300px, 4fr));

    }

}

@media screen and (max-width:375px) {
    .cardbutton {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .cardbuttonno {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
    }
}