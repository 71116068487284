@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.container {
    font-family: 'Nunito', sans-serif;
    background-color: #212324;

    display: flex;
    min-height: 93vh;
    width: 100%;
}

.left {
    width: 60%;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: auto;
    margin-top: 80px;
}

.leftup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    margin-left: 10%;
    height: 60%;
}


.text1 {
    color: rgb(0, 0, 0);
    background-color: #59e1ac;
    width: 28%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    border-radius: 3px;
    font-size: 15px;
    padding: 4px;

}

.text1:hover {
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 1000ms;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.text2 {
    font-size: 100px;
    color: white;
}

.text3 {
    font-size: 70px;
    color: white;
}

.text4 {
    color: white;
    font-size: 17px;
    font-weight: 100;
}

.text5 {
    margin-top: 40px;
    color: #59e1ac;
    font-size: 30px;
    display: flex;
    max-height: 40px;
}



.resume {
    color: #59e1ac;
    margin-left: 15px;
    font-size: 40px;
    margin-bottom: 20px;
}

.leftbottom {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-left: 10%;
    gap: 80px;
    margin-top: 10px;

}

.homeicon {
    width: 120px;
    color: white;
    padding: 4px;
    border: none;
    border-radius: 10px;
    background-color: hsla(0, 0%, 100%, .1);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 10px;
    transition: all 500ms ease;

}

.homeicon:hover {
    color: #59e1ac;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
}

.homeiconmain {
    font-size: 20px;
    margin-right: 5px;
}


.right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pic {
    height: 70%;
    width: 70%;
    object-fit: cover;
    box-shadow: 1px -5px 10px 1px rgba(0.5, 0, 0, 0.5);
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;

    border-left: 3px solid #59e1ac;
    border-right: 3px solid #59e1ac;
}

a {
    color: white;
    text-decoration: none;
}

.iconlink {

    transition: all 500ms ease;
}

.iconlink:hover {
    color: #59e1ac;
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 1000ms;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.forunder {
    border-bottom: 2px solid #59e1ac;

}

@media screen and (max-width:1326px) {
    .right {
        width: 50%;

    }
}

@media screen and (max-width:1174px) {
    .right {
        width: 55%;

    }

    .text1 {
        width: 50%;
    }
}

@media screen and (max-width:1008px) {
    .right {
        width: 55%;

        margin: auto;
    }

}

@media screen and (max-width:842px) {
    .leftup {
        margin-left: 0;
        margin-right: 0;
    }

    .container {
        flex-direction: column-reverse;
        margin-top: 0px;
    }

    .right {
        display: none;
    }

    .pic {
        width: 100%;
        height: 600px;

    }

    .left {
        width: 100%;
        margin-left: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .leftbottom {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .text1 {
        margin: auto;
    }

    .text5 {
        justify-content: center;

    }
}

@media screen and (max-width:600px) {
    .container {
        margin-top: -50px;
    }
}

@media screen and (max-width:410px) {
    .text2 {
        font-size: 80px;
    }

    .text3 {
        font-size: 60px;
    }

    .text5 {
        justify-content: center;
        font-size: 25px;
    }

    .leftbottom {
        gap: 20px;
    }

    .container {
        margin-top: -80px;
    }
}

@media screen and (max-width:385px) {
    .text1 {
        font-size: 12px;
    }
}