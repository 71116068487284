/* Lato font */
@import url("https://fonts.googleapis.com/css?family=Mukta:400,700&display=swap");

.contaienr1 {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

button.learn-more {
    width: 16rem;
    height: auto;
    display: flex;
    margin: auto;


}

button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
}

button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}

button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: '';
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}

button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: rgb(127, 205, 129);
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

button.learn-more:hover .circle {
    width: 100%;
}

button.learn-more:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
}

button.learn-more:hover .button-text {
    color: #fff;
}



body {
    margin: 0;
    padding: 0;
    background-color: #1f1f23;
    transition: all 500ms ease-in-out;
    overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 5px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #212324;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #212324;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #59e1ac;
}


.download-button {
    position: relative;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}

.download-button .text {
    position: relative;
    z-index: 2;
}

.download-button .icon {
    position: absolute;
    top: 100%;
    left: 50%;
    font-size: 30px;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation: moveUp 1s ease-in-out infinite, fadeIn 1s ease-in-out infinite;
}

@keyframes moveUp {

    0%,
    100% {
        top: 100%;
        opacity: 0;
        transform: translate(-50%, -50%);
    }

    50% {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.2);
    }
}

@keyframes fadeIn {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.download-button:hover .icon {
    animation: rotateAndBounce 1s ease-in-out infinite, pulsate 1s ease-in-out infinite;
}

@keyframes rotateAndBounce {

    0%,
    100% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }

    25% {
        transform: translate(-50%, -50%) rotate(-20deg) scale(1.2);
    }

    50% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }

    75% {
        transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
    }
}

@keyframes pulsate {

    0%,
    100% {
        background-color: #3498db;
    }

    50% {
        background-color: #e74c3c;
    }
}

@media screen and (min-width:500px) {
    .cursor {
        z-index: 1000000;
        background-color: #f6f3f3;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 200px;
        pointer-events: none;
    }
}

@media screen and (max-width:500px) {
    * {
        cursor: auto;
    }

}