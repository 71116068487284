@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.container {
    display: flex;
    width: 90%;
    flex-direction: column;
    background-color: black;
    margin: auto;
    margin-top: 50px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    color: white;

    margin-bottom: 80px;
    padding-bottom: 40px;
}

.first {
    display: flex;
    flex-direction: row;
    gap: 0;
    padding: 30px;
    padding-bottom: 0;
}

.firstheading {
    color: #aaaaaa;
    font-size: 20px;
}

.firstline {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #62cf78;
    line-height: 0.1em;
    margin: 10px 0 20px;
    margin-bottom: 31px;
    width: 130px;
    margin-left: 20px;

}

.second {
    padding: 30px;
    padding-top: 0;
    margin-top: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 0;
}

.third {
    padding: 30px;
    display: flex;
    flex-direction: row;
    gap: 3%;
}

.third1 {
    background-color: #141414;
    padding: 10px;
    display: flex;
    width: 40%;

}





form {
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.form1 {
    display: flex;
    gap: 10%;
}

.eachinputdiv {
    display: flex;
    flex-direction: column;
}

.eachinputdiv1 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.eachinputdiv2 {
    display: flex;
    flex-direction: column;
    width: 45%;
}

label {
    color: #8b8b8b;
}

input {
    background-color: #262626;
    border: none;
    height: 30px;
    color: white;
}

textarea:focus,
input:focus {
    outline: none;
}

textarea {
    border: none;
    background-color: #262626;
    color: white;

}

.button {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
    text-align: center;
    transition: all 500ms ease-in-out;
    color: white;
    font-size: 25px;
    font-weight: 400;
    background-color: #28d44a;
    padding: 6px 30px;
}

.button:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #28d44a;
}

.third2 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 3%;
}

.third2_1 {
    display: flex;
    height: 30%;
    ;
}

.itemup {
    display: flex;
    color: #28d44a;
    font-size: 33px;
    font-weight: bolder;
    justify-content: center;
    align-items: center;

}

.itemdown {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;

}

.itemdownicon {
    color: #62cf78;
    font-size: 20px;
}

.itemdownh {
    color: #858585;
    font-size: 20px;
    font-weight: bold;
}

.itemcontent {
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-top: 9px;
}

.third3 {
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    justify-content: center;
}

.third3_1 {
    font-size: 30px;
    font-weight: bold;

}

.share {
    color: #62cf78;
    transition: all 500ms ease;
}

.share:hover {
    transform: rotate(180deg);
}

.socialicons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 30px;
}

.social {
    transition: all 500ms ease;
    color: white;

}

.social:hover {
    color: #28d44a;
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 500ms;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@media screen and (max-width:1193px) {
    .third {
        flex-direction: column-reverse;
        gap: 50px;
    }

    .third1 {
        width: 90%;
        margin: auto;
    }

    .third2 {
        width: 100%;

        gap: 20px;

    }

    .itemdown {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;

    }

    .third3 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        margin-top: -10px;
        margin-bottom: -40px;
    }


}