@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.container {
    display: flex;
    width: 100%;
    background-color: #212324;
    min-height: 70px;
    color: white;
}

.containerleft {
    height: 100%;
    font-family: 'Nunito', sans-serif;
    font-size: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    width: 40%;
    margin-left: 5.5%;
}

.containerright {
    margin-right: 4.5%;
    width: 60%;
    display: flex;
    text-align: end;
    gap: 5%;
    justify-content: flex-end;
    align-items: center;
}

.item {
    font-family: 'Nunito', sans-serif;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 1px;
    list-style-type: none;
    height: 30px;
    transition: all 0.3s ease-in-out;
}

.item1 {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    transition: all 0.2s ease-in-out;
    background-color: #59e1ac;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
    color: black;
    font-size: 18px;
    border-radius: 2px;
}

.item1:hover {}

.item:hover {
    background-color: hsla(0, 0%, 100%, .1);
    border-radius: 6px;
}

.active {
    background-color: hsla(0, 0%, 100%, .1);
    color: rgb(255, 255, 255);
    border-radius: 6px;
}

.bar {
    display: none;
}

@media screen and (max-width:1100px) {

    .containerleft {

        font-size: 30px;

    }

    .item {
        font-size: 14px;
    }

    .item1 {
        font-size: 14px;
    }
}

@media screen and (max-width:927px) {

    .containerleft {

        font-size: 25px;

    }

    .item {
        font-size: 10px;
    }

    .item1 {
        font-size: 10px;
        padding: 2px;
    }


}

@media screen and (max-width:800px) {
    .item {
        font-size: 20px;
    }

    .item1 {
        font-size: 20px;
        padding: 5px;
        padding-top: 2px;
    }

    .container {
        flex-direction: column;
    }

    .containerright {
        flex-direction: column;
        position: static;
        gap: 10px;
        height: 200px;

        margin: auto;
        margin-top: 40px;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        padding: 40px;
        transition: all 300ms ease;
        transform: translateX(200%);

    }

    .bar {
        display: block;
        font-size: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        border-radius: 5px;
        transition: all 500ms ease;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);

    }

    .bar:hover {}

    .toggle {
        transform: translateX(0%);

    }

    .hide {
        display: none;
    }

    .containerleft {
        width: 80%;
    }
}

* {
    cursor: none;
}